<template>
  <section class="content px-4 py-2 my-2" name="MassiveOrders">
    <v-layout wrap>
      <v-flex xs12>
        <AppTitle title="Categorias del blog" subtitle="---" />
      </v-flex>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="items"
          :pagination.sync="pagination"
          :rows-per-page-items="rowsPerPage"
          class="elevation-1"
        >
          <template v-slot:items="props">
            <td>
              <a :href="`https://www.rosalinda.cl/blog.html/categoria/${props.item.url}`" target="_blank">
                {{ props.item.name }}
              </a>
            </td>
            <td class="text-xs-center">
              <AppStatus :value="props.item.status"></AppStatus>
            </td>
            <td class="text-xs-right">
              <v-icon small class="mr-2" @click="newItem(props.item.id)">
                edit
              </v-icon>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-btn fab dark fixed bottom right color="success" @click="newItem()">
      <v-icon>add</v-icon>
    </v-btn>
  </section>
</template>

<script>
import { GET_BLOG_CATEGORIES } from '../../../config'
import AppTitle from '../../useful/title.vue'
import AppStatus from '../../useful/status.vue'

export default {
  name: 'SettingsBlogPosts',
  components: {
    AppTitle,
    AppStatus
  },
  data() {
    return {
      items: [],
      dialog: false,
      headers: [
        { text: 'Titulo', align: 'left', sortable: false },
        { text: 'Estado', align: 'center', sortable: false },
        { text: '', align: 'left', sortable: false }
      ],
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }]
    }
  },
  mounted() {
    this.getMsjWsp()
  },
  methods: {
    async getMsjWsp() {
      const res = await this.$http.get(GET_BLOG_CATEGORIES)
      this.items = res.data
    },
    newItem(e) {
      e = e === undefined ? 'nuevo' : e
      this.$router.push({ name: 'settingsBlogCategoryId', params: { id: e } })
    }
  }
}
</script>
